import { utcToCommuneDateTimeZone } from '@/communes/composables/CommuneContractHelpers';
import CommuneTableNameRender from '@/communes/components/CommuneTableNameRender.vue';
import CommuneFilterByDates from '@/communes/components/CommuneFIlterByDates.vue';
import CommunesTableActions from '@/communes/components/CommunesTableActions.vue';
import CommunesTableActionsMissingJson from '@/communes/components/CommunesTableActionsMissingJson.vue';
import CommuneHeaderState from '@/communes/components/CommuneHeaderState.vue';

import { TableColumn } from 'naive-ui/lib/data-table/src/interface';
import { CommuneAdminDto } from '@/typings/commune';
import { TitleTranslation } from '@/core/composables';
import { h, ref, VNode } from 'vue';
import { NDropdown } from 'naive-ui';
import { i18n } from '@/i18n';
import { LicenceTypeDto } from '@/typings/api';
import { IconLocal } from '@/Dashboard';
import { type PendingCommuneOverview } from '@/services/ReportServiceCommune';
import { DeepRequired } from '@/stores/GlobalStore';
import CommuneTableFilter from '../components/CommuneTableFilter.vue';

export type TableEventEmitterKey = 'DELETE' | 'OPEN' | 'EMAIL' | 'EDIT' | 'SET_ACTIVE' | 'FORCE_SYNC' | 'SYNC_REPORTS';

export type TableHeaderMaker<T, E> = ({
  clickHandler,
}: {
  clickHandler: (row: T, eventEmitterKey: E) => void;
}) => TableColumn<T>[];

export type HEADER_ROW<T> = Required<DeepRequired<T>>;
const translationBasePath = 'commune.table.header.labels.';
const headerActions = ref<boolean>(false);
const activeCommuneUuid = ref('');
const header = ref<TableColumn<HEADER_ROW<CommuneAdminDto>>[]>([]);

const PublicCommuneTableHeader = ({
  clickHandler,
}: {
  clickHandler: (row: HEADER_ROW<CommuneAdminDto>, eventEmitterKey: TableEventEmitterKey) => void;
}) => {
  header.value = [
    {
      title: () => '',
      key: 'manualName',
      children: [
        {
          title: () => TitleTranslation('name', translationBasePath),
          key: 'manualName',
          width: 250,
          render(row) {
            return h(
              CommuneTableNameRender,
              {
                label: row.details.manualName,
                active: row.details.status?.active,
                exist: row.details.status?.exists,
                reportingValid: row.details.status?.reportingPeriodValid,
                communeUuid: row.details.uuid,
              },
              {},
            );
          },
          sorter: true,
          renderSorterIcon() {
            return h(IconLocal, { type: 'Sort', size: '9' });
          },
        },
      ],
    },
    {
      title: () => '',
      key: 'manualName',
      children: [
        {
          title: () => TitleTranslation('federalState', translationBasePath),
          key: 'details.federalState',
          width: 250,
          render(row) {
            return h(
              CommuneHeaderState,
              {
                stateId: row.details.federalState,
              },
              {},
            );
          },
        },
      ],
    },
    {
      title: () => '',
      key: 'regionKey',
      children: [
        {
          title: () => TitleTranslation('id', translationBasePath),
          key: 'details.regionKey',
          width: 150,
          ellipsis: {
            tooltip: true,
          },
          sorter: true,
          renderSorterIcon() {
            return h(IconLocal, { type: 'Sort', size: '9' });
          },
        },
      ],
    },
    {
      title: () => TitleTranslation('managementPeriod', translationBasePath),
      key: 'managementPeriod',
      width: 250,
      children: [
        {
          title: () => TitleTranslation('start', translationBasePath),
          key: 'contractData.adminStart',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const adminStart = utcToCommuneDateTimeZone({
              date: row.details.contractData?.adminStart || new Date().toDateString(),
            });
            return h('span', {}, { default: () => i18n.global.d(adminStart, { key: 'medium' }) });
          },
          sorter: true,
          renderSorterIcon() {
            return h(IconLocal, { type: 'Sort', size: '9' });
          },
        },
        {
          title: () => TitleTranslation('end', translationBasePath),
          key: 'contractData.adminEnd',
          width: 150,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const adminEnd = utcToCommuneDateTimeZone({
              date: row.details.contractData?.adminEnd || new Date().toDateString(),
            });
            return h('div', {}, { default: () => i18n.global.d(adminEnd as string, { key: 'medium' }) });
          },
          sorter: true,
          renderSorterIcon() {
            return h(IconLocal, { type: 'Sort', size: '9' });
          },
        },
      ],
    },
    {
      title: () => TitleTranslation('reportPeriod', translationBasePath),
      key: 'areaId',
      children: [
        {
          title: () => TitleTranslation('start', translationBasePath),
          key: 'contractData.reportStart',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const reportStart = utcToCommuneDateTimeZone({
              date: row.details.contractData?.reportStart || new Date().toDateString(),
            });
            return h('span', {}, { default: () => i18n.global.d(reportStart as string, { key: 'medium' }) });
          },
          sorter: true,
          renderSorterIcon() {
            return h(IconLocal, { type: 'Sort', size: '9' });
          },
        },
        {
          title: () => TitleTranslation('end', translationBasePath),
          key: 'contractData.reportEnd',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const reportEnd = utcToCommuneDateTimeZone({
              date: row.details.contractData?.reportEnd || new Date().toDateString(),
            });
            return h('span', {}, { default: () => i18n.global.d(reportEnd as string, { key: 'medium' }) });
          },
          sorter: true,
          renderSorterIcon() {
            return h(IconLocal, { type: 'Sort', size: '9' });
          },
        },
      ],
    },
    {
      title: () => '',
      key: 'actions',
      children: [
        {
          title() {
            return h(
              'div',
              { class: 'rd-flex rd-justify-end' },

              h(
                NDropdown,
                {
                  options: [
                    {
                      key: 'header',
                      type: 'render',
                      render: () => h(CommuneFilterByDates, {}, {}),
                    },
                  ],
                  themeOverrides: {
                    // color: 'var(--radar-base-colors-blue-300)',
                    optionColorHover: 'var(--radar-base-colors-blue-400)',
                    borderRadius: '5px',
                    padding: '0px',
                  },
                  trigger: 'click',
                },
                {
                  default: () => h(CommuneTableFilter, {}),
                },
              ),
            );
          },
          key: 'action',
          width: 200,
          render(rowData): VNode {
            return h(CommunesTableActions, {
              communeUuid: rowData.details.uuid || '',
              headerActions: headerActions.value,
              sendEmail: false,
              onTrigger: (key: TableEventEmitterKey) => clickHandler(rowData, key),
            });
          },
        },
      ],
    },
  ];
  return header as unknown as TableColumn<HEADER_ROW<CommuneAdminDto>>[];
};
const setUserActions = (state: boolean) => {
  headerActions.value = state;
};
export const useSetActiveCommuneUuidForTheTable = ({ uuid }: { uuid: string }) => {
  activeCommuneUuid.value = uuid;
};

export const pendingCommuneTableHeader = ({
  clickHandler,
  licenseOptions,
}: {
  clickHandler: (row: Required<PendingCommuneOverview>, eventEmitterKey: TableEventEmitterKey) => void;
  licenseOptions: Array<LicenceTypeDto>;
}) => {
  const header = ref<TableColumn<Required<PendingCommuneOverview>>[]>([
    {
      title: () => '',
      key: 'manualName',
      children: [
        {
          title: () => TitleTranslation('name', translationBasePath),
          key: 'manualName',
          width: 250,
        },
      ],
    },
    {
      title: () => '',
      key: 'upId',
      children: [
        {
          title: () => TitleTranslation('id', translationBasePath),
          key: 'regionKey',
          width: 150,
          ellipsis: {
            tooltip: true,
          },
        },
        {
          title: () => TitleTranslation('license', translationBasePath),
          key: 'licenseId',
          width: 150,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const lic = licenseOptions.find((o) => o.id === row.licenseId);
            return lic?.description || lic?.name || row.licenseId;
          },
        },
      ],
    },
    {
      title: () => TitleTranslation('reportPeriod', translationBasePath),
      key: 'areaId',
      children: [
        {
          title: () => TitleTranslation('start', translationBasePath),
          key: 'contractData.reportStart',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            return h('span', {}, { default: () => i18n.global.d(row.reportStart, { key: 'medium' }) });
          },
        },
        {
          title: () => TitleTranslation('end', translationBasePath),
          key: 'contractData.reportEnd',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            return h('span', {}, { default: () => i18n.global.d(row.reportEnd, { key: 'medium' }) });
          },
        },
      ],
    },
    {
      title: () => '',
      key: 'actions',
      children: [
        {
          title: '',
          key: 'action',
          width: 200,
          render(rowData): VNode {
            return h(CommunesTableActions, {
              communeUuid: rowData.externalId || '',
              headerActions: true,
              sendEmail: true,
              showSetActive: false,
              onTrigger: (key: TableEventEmitterKey) => clickHandler(rowData, key),
            });
          },
        },
      ],
    },
  ]);
  return header as unknown as TableColumn<Required<PendingCommuneOverview>>[];
};

const communeHeaderMissingGeoJson = ref<TableColumn<HEADER_ROW<CommuneAdminDto>>[]>([]);
export const communesMissingGeoJsonTableHeader = ({
  clickHandler,
}: {
  clickHandler: (row: HEADER_ROW<CommuneAdminDto>, eventEmitterKey: TableEventEmitterKey) => void;
}) => {
  communeHeaderMissingGeoJson.value = [
    {
      title: () => '',
      key: 'manualName',
      children: [
        {
          title: () => TitleTranslation('name', translationBasePath),
          key: 'manualName',
          width: 250,
          render(row) {
            return h(
              CommuneTableNameRender,
              {
                label: row.details.manualName,
                active: row.details.status?.active,
                exist: row.details.status?.exists,
                reportingValid: row.details.status?.reportingPeriodValid,
                communeUuid: row.details.uuid,
              },
              {},
            );
          },
        },
      ],
    },
    {
      title: () => '',
      key: 'regionKey',
      children: [
        {
          title: () => TitleTranslation('id', translationBasePath),
          key: 'details.regionKey',
          width: 150,
          ellipsis: {
            tooltip: true,
          },
        },
      ],
    },
    {
      title: () => TitleTranslation('managementPeriod', translationBasePath),
      key: 'managementPeriod',
      width: 250,
      children: [
        {
          title: () => TitleTranslation('start', translationBasePath),
          key: 'contractData.adminStart',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const adminStart = utcToCommuneDateTimeZone({
              date: row.details.contractData?.adminStart || new Date().toDateString(),
            });
            return h('span', {}, { default: () => i18n.global.d(adminStart, { key: 'medium' }) });
          },
        },
        {
          title: () => TitleTranslation('end', translationBasePath),
          key: 'contractData.adminEnd',
          width: 150,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const adminEnd = utcToCommuneDateTimeZone({
              date: row.details.contractData?.adminEnd || new Date().toDateString(),
            });
            return h('div', {}, { default: () => i18n.global.d(adminEnd as string, { key: 'medium' }) });
          },
        },
      ],
    },
    {
      title: () => TitleTranslation('reportPeriod', translationBasePath),
      key: 'areaId',
      children: [
        {
          title: () => TitleTranslation('start', translationBasePath),
          key: 'contractData.reportStart',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const reportStart = utcToCommuneDateTimeZone({
              date: row.details.contractData?.reportStart || new Date().toDateString(),
            });
            return h('span', {}, { default: () => i18n.global.d(reportStart as string, { key: 'medium' }) });
          },
        },
        {
          title: () => TitleTranslation('end', translationBasePath),
          key: 'contractData.reportEnd',
          width: 120,
          ellipsis: {
            tooltip: true,
          },
          render(row) {
            const reportEnd = utcToCommuneDateTimeZone({
              date: row.details.contractData?.reportEnd || new Date().toDateString(),
            });
            return h('span', {}, { default: () => i18n.global.d(reportEnd as string, { key: 'medium' }) });
          },
        },
      ],
    },
    {
      title: () => '',
      key: 'actions',
      children: [
        {
          key: 'action',
          width: 200,
          render(rowData): VNode {
            return h(CommunesTableActionsMissingJson, {
              onTrigger: (key: TableEventEmitterKey) => clickHandler(rowData, key),
            });
          },
        },
      ],
    },
  ];
  return communeHeaderMissingGeoJson as unknown as TableColumn<HEADER_ROW<CommuneAdminDto>>[];
};
export { PublicCommuneTableHeader, setUserActions as TableSetUserActions };
